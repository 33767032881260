.footer {
  background-color: rgb(212, 212, 212);
  color: #3a0c9d;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  padding-top: 10px;
  border-top: 2px solid #ffc107;
}

/* Footer Content Layout */
.footer-content {
  display: flex;
  justify-content: space-around;
  
  
  
  padding: 0 20px;
}

/* Footer Sections */
.footer-section {
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  
}

.footer-heading {
  font-family: 'Archivo Black', sans-serif;
  font-size: 1.6rem;
  color: #560991;
  
  border-bottom: 2px solid var(--retro-accent-color);
  padding-bottom: 5px;
  text-transform: uppercase;
  color:#232224
 
}

/* Hover Effect for Headings */
.footer-heading:hover {
  color: var(--retro-accent-color);
  border-color: #fff;
}

.footer-text {
  font-size: 0.9rem;
  color:#232224;
  margin: 5px 0;
 
}

.footer-section a {
  color: #232224;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
  font-weight: 500;
}

/* Hover Effect for Links */
.footer-section a:hover {
  color: var(--retro-accent-color);
  transform: translateX(5px);
  text-decoration: underline;
}

/* Group Companies & Branches Styling */
.footer-section ul {
  
  list-style: none;
  padding: 0;
}

.footer-section li {
 
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
}

/* Hover Effect for List Items */
.footer-section li:hover {
  transform: translateX(10px);
}

/* Hover Effect for Logos */
.company-logo,
.company-logo1 {
  max-height: 60px;
  margin-right: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.company-logo1 {
  max-height: 130px;
  
}

/* Logos Hover */
.company-logo:hover,
.company-logo1:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  background-blend-mode: darken;
}

/* Footer Bottom Section */
.footer-bottom {
  font-size: 0.875rem;
  border-top: 1px solid var(--retro-accent-color);
  color: #232224;
  transition: color 0.3s ease;
}
.footer-text1{
  Color:#232224;
}

/* Hover Effect for Footer Bottom Text */
.footer-bottom:hover {
  color: var(--retro-accent-color);
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    text-align: center;
    min-height: auto;
    
  }
}
