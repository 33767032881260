/* General Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Main Container */
.contact-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(to bottom, #fdfdfd, #e9e9e9);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 800px;
  margin: auto;
  gap: 20px; /* Space between elements */
}

/* Photo Container */
.photo-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  background: #8a8989;
  border-radius: 12px;
  width: 100%;
  max-width: 750px;
  height: auto; /* Flexible height */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  border: 2px solid #ffbc05;
  justify-content: center;
}

.photo-container img {
  width: 150px;
  height: 100px;
  object-fit: contain;
  border-radius: 12px;
  background: #fff;
  padding: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.photo-container img:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(255, 193, 7, 0.7);
}

/* Card Content */
.card-content {
  background: #8a8989;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 750px;
  text-align: center;
  border: 2px solid #ffbc05;
  color: white;
}

/* Card Header */
.card-content h2 {
  font-size: 2rem;
  color: #ffc107;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Contact Details */
.contact-details {
  font-size: 1rem;
  color: #ffffff;
  line-height: 1.8;
  margin: 10px 0;
  text-align: left;
}

.contact-details p,
.contact-details a {
  margin: 8px 0;
}

.contact-details a {
  color: #ffbc05;
  text-decoration: none;
  font-weight: bold;
}

.contact-details a:hover {
  text-decoration: underline;
}

/* Icon Group */
.icon-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.action-icon {
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.action-icon:hover {
  transform: scale(1.1);
  background: rgb(10, 138, 242);
  box-shadow: 0 0 10px rgba(10, 138, 242, 0.7);
}

/* Responsive Design */

/* 320px to 425px (First Image Style) */
@media (max-width: 425px) {
  .photo-container {
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
  }

  .photo-container img {
    width: 45%; /* Two images per row */
    height: auto;
  }

  .card-content {
    padding: 15px;
    font-size: 0.9rem;
  }

  .icon-group img {
    width: 30px;
    height: 30px;
  }
}@media (max-width: 430px) {
  .photo-container {
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
  }

  .photo-container img {
    width: 45%; /* Two images per row */
    height: auto;
  }

  .card-content {
    padding: 15px;
    font-size: 0.9rem;
  }

  .icon-group img {
    width: 30px;
    height: 30px;
  }
}

/* 768px and Higher (Second Image Style) */
@media (min-width: 768px) {
  .photo-container {
    flex-wrap: nowrap;
    justify-content: space-around;
    padding: 20px;
  }

  .photo-container img {
    width: 150px;
    height: 100px;
  }

  .card-content {
    padding: 30px;
    font-size: 1rem;
  }

  .icon-group img {
    width: 50px;
    height: 50px;
  }
}

/* 1024px */
@media (min-width: 1024px) {
  .photo-container img {
    width: 150px;
    height: 130px;
  }

  .card-content h2 {
    font-size: 2rem;
  }
}

/* 1440px */
@media (min-width: 1440px) {
  .photo-container img {
    width: 150px;
    height: 140px;
  }

  .card-content h2 {
    font-size: 2.2rem;
  }
}

/* 2560px */
@media (min-width: 2560px) {
  .photo-container img {
    width: 220px;
    height: 160px;
  }

  .card-content {
    font-size: 1.2rem;
    padding: 40px;
  }

  .icon-group img {
    width: 60px;
    height: 60px;
  }
}
