/* General Container */
.services-container {
  padding: 30px;
  padding-top: 20px; /* Adjust for fixed navbar height */
  background: linear-gradient(to bottom, #e8d8e3, #584848);
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures the content and footer spacing are balanced */
}

/* Title and Description */
.services-title {
  font-size: 36px;
  font-weight: bold;
  color: #141002;
  margin-top:auto !important;
}

.services-description {
  font-size: 18px;
  color: #110116;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top:auto !important; /* Reduced gap */
}

/* Services Grid */
.services-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 5px;
}

/* Service Card */
.service-card {
  width: 280px;
  padding: 20px;
  background: #222;
  border-radius: 10px;
  text-decoration: none;
  color: white;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.service-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.7);
  background: linear-gradient(to bottom, #444, #555);
  cursor: pointer;
}

/* Service Content */
.service-content {
  text-align: center;
}

/* Service Icon */
.service-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 15px;
  transition: transform 0.3s ease;
}

.service-card:hover .service-icon {
  transform: rotate(10deg) scale(1.2);
}

/* Service Title */
.service-title {
  font-size: 20px;
  font-weight: bold;
  color: #ffc107;
  transition: color 0.3s ease;
}

.service-card:hover .service-title {
  color: #ffffff;
}

/* Service Description */
.service-description {
  font-size: 14px;
  color: #ccc;
  line-height: 1.6;
}

/* Footer Adjustment */
.services-container::after {
  content: '';
  display: block;
  height: 50px; /* Buffer space for smooth alignment with the footer */
}

/* Responsive Design */
@media (max-width: 1024px) {
  .service-card {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .service-card {
    width: 90%;
  }

  .service-icon {
    width: 80px;
    height: 80px;
  }

  .service-title {
    font-size: 18px;
  }

  .service-description {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .services-title {
    font-size: 28px;
  }

  .services-description {
    font-size: 16px;
  }

  .service-icon {
    width: 70px;
    height: 70px;
  }

  .service-title {
    font-size: 16px;
  }

  .service-description {
    font-size: 12px;
  }
}
