/* Main Container */
.transportation-container {
    padding: 20px;
    padding-top: 20; /* Adjust for fixed navbar height */
    background: linear-gradient(to bottom, #1A1A1A, #333);
    color: #fff;
    font-family: 'Arial', sans-serif;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
    max-width: 800px;
    margin: 20px auto;
    text-align: center;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  /* Title Styling */
  .transportation-title {
    font-size: 28px;
    color: #ffc107;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
    margin-bottom: 20px;
  }
  
  /* Description Styling */
  .transportation-description {
    font-size: 16px;
    line-height: 1.8;
    color: #ddd;
    margin-bottom: 30px;
    text-align: justify;
  }
  
  /* Rate Calculator Section */
  .rate-calculator {
    background: #444;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    margin-top: 20px;
  }
  
  .rate-calculator h2 {
    font-size: 22px;
    color: #ffc107;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .rate-calculator p {
    font-size: 14px;
    color: #ddd;
    line-height: 1.5;
  }
  
  .highlight {
    color: #ffc107;
    font-weight: bold;
  }
  
  /* Hover Effect */
  .transportation-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
    transition: all 0.3s ease-in-out;
  }
  
  /* Fade-in Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .transportation-container {
      padding: 15px;
    }
  
    .transportation-title {
      font-size: 24px;
    }
  
    .transportation-description {
      font-size: 14px;
    }
  
    .rate-calculator h2 {
      font-size: 18px;
    }
  
    .rate-calculator p {
      font-size: 12px;
    }
  }
  