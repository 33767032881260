/* General Styling */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #000000;
}

.rcp-services-container {
  padding: 20px;
  background-color: #565555;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px auto;
  max-width: 1200px;
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
  color: #ffffff;
}

p {
  font-size: 1rem;
  color: #ffffff;
  line-height: 1.6;
  text-align: center;
}

/* Block Details Section */
.block-details-section {
  margin-top: 20px;
}

.block-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.block-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.block-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.block-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.block-details {
  padding: 15px;
  text-align: center;
}

.block-details h4 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #222;
}

.block-details p {
  font-size: 0.9rem;
  color: #555;
  margin: 5px 0;
}

/* Request for Quote Button */
.request-quote-button {
  display: block;
  margin: 20px auto;
  padding: 12px 20px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.request-quote-button:hover {
  background-color: #0056b3;
}

/* Quote Form Popup */
.quote-form-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1000;
  width: 90%;
  max-width: 500px;
  padding: 20px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #333;
  background: none;
  border: none;
  cursor: pointer;
}

.quote-form-heading h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.quote-form-heading p {
  font-size: 1rem;
  color: #555;
  text-align: center;
  margin-bottom: 20px;
}

.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px 15px;
  margin-bottom: 20px;
}

.block-checkbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.block-checkbox label {
  flex: 1;
  color: #333;
}

.block-checkbox input[type="checkbox"] {
  margin-right: 10px;
}

.block-checkbox input[type="number"] {
  width: 80px;
  padding: 5px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.select-location-button {
  display: block;
  margin: 10px 0;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

@media (max-width: 430px) {
  .quote-form-container {
    max-height: 70vh; /* Adjust height to fit within the viewport */
    overflow-y: auto; /* Enable vertical scrolling */
    padding-right: 10px; /* Add padding to avoid content cutoff */
  }
}

/* Adjust scrollbar appearance (optional) */
.quote-form-container::-webkit-scrollbar {
  width: 8px;
}

.quote-form-container::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Scrollbar thumb color */
  border-radius: 4px;
}

.quote-form-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa; /* Scrollbar thumb hover color */
}
  .checkbox-grid {
    grid-template-columns: 1fr; /* Ensure fields stack */
  }

  .block-checkbox {
    flex-wrap: wrap; /* Allow items to wrap */
    justify-content: space-between; /* Align items properly */
  }

  .block-checkbox input[type="checkbox"],
  .block-checkbox label,
  .block-checkbox input[type="number"] {
    flex: 1 1 15%; /* Make each field take full width */
    max-width: 100%; /* Ensure they don't exceed container width */
  }

  .block-checkbox input[type="number"] {
    margin-top: 5px; /* Add spacing between rows */
  }

/* For resolutions between 320px and 375px */
@media (max-width: 375px) {
  .checkbox-grid{
    height:auto;
  }
  .block-checkbox input[type="checkbox"] {
    flex: 0 0 auto; /* Adjust size for the checkbox */
  }

  .block-checkbox label {
    margin-left: 5px; /* Add slight spacing */
  }

  .block-checkbox input[type="number"] {
    margin-top: 10px;
  }
  .whatsapp-button{
    width: 90px;
    margin:2px;
  }
}

/* For resolutions below 320px */
@media (max-width: 320px) {
  .block-checkbox input[type="checkbox"] {
    flex: 0 0 auto;
  }

  .block-checkbox label {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }

  .block-checkbox input[type="number"] {
    margin-top: 10px;
    width: 20%; /* Full width for smaller screens */
  }
}

/* 768px */
@media (max-width: 768px) {
  .block-grid {
    grid-template-columns: 1fr;
  }

  h1 {
    font-size: 2rem;
  }

  .quote-form-popup {
    width: 90%;
  }
}

/* 1024px */
@media (max-width: 1024px) {
  .block-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  h1 {
    font-size: 2.2rem;
  }

  .quote-form-popup {
    width: 80%;
  }
}

/* 1440px */
@media (min-width: 1440px) {
  .block-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  h1 {
    font-size: 2.5rem;
  }
}

/* 1920px */
@media (min-width: 1920px) {
  .block-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  h1 {
    font-size: 3rem;
  }
}

/* 2560px */
@media (min-width: 2560px) {
  .block-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  h1 {
    font-size: 3.5rem;
  }
}

/* 4K */
@media (min-width: 3840px) {
  .block-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
  }

  h1 {
    font-size: 4rem;
  }
}
