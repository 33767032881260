* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Navbar Container */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(137, 137, 137);
  position: relative;
  height: 100px;
  z-index: 10;
  padding: 10px 20px;
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.66);
}

/* Inner Navbar */
.inner-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* Logo Section */
.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  width: 120px;
  height: auto;
  object-fit: contain;
  transition: all 0.4s ease;
}

.navbar-logo img:hover {
  transform: scale(1.1);
}

/* Navigation Links */
.navbar-links {
  display: flex;
  gap: 20px;
  align-items: center;
  transition: max-height 0.3s ease-in-out;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  white-space: nowrap; /* Prevent wrapping */
  text-align: center;
}

.nav-link:hover {
  color: white;
  background-color: rgb(143, 88, 198);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Shadow for hover effect */
  transform: translateY(-2px); /* Slight lift effect */
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: white;
  border-radius: 3px;
}

/* Dropdown Menu for Mobile */
.navbar-links.open {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 80px;
  right: 0;
  background-color: rgb(77, 77, 77);
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  z-index: 5;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.navbar-links a {
  width: 100%;
  text-align: left;
  padding: 10px 20px;
}

/* Responsive Design */

/* Small Devices (320px) */
@media screen and (max-width: 320px) {
  .hamburger {
    display: flex;
    padding-right: 20px;
  }

  .navbar-links {
    display: none;
  }

  .navbar-links.open {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-link {
    display: block;
    width: 100%;
  }

  .heading {
    display: none;
  }

  .navbar-logo img {
    width: 80px;
  }
}

/* Small Phones (375px) */
@media screen and (max-width: 375px) {
  .hamburger {
    display: flex;
  }

  .navbar-links {
    display: none;
  }

  .navbar-links.open {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-link {
    display: block;
    width: 100%;
  }

  .heading {
    display: none;
  }

  .navbar-logo img {
    width: 90px;
  }
}

/* Large Phones (425px) */
@media screen and (max-width: 425px) {
  .hamburger {
    display: flex;
  }

  .navbar-links {
    display: none;
  }

  .navbar-links.open {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-link {
    display: block;
    width: 100%;
  }

  .heading {
    display: none;
  }

  .navbar-logo img {
    width: 100px;
  }
}

/* Tablets (768px) */
@media screen and (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .navbar-links {
    display: none;
  }

  .navbar-links.open {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-link {
    display: block;
    width: 100%;
  }

  .heading {
    display: none;
  }

  .navbar-logo img {
    width: 110px;
  }
}

/* Laptops and Desktops */
@media screen and (min-width: 1024px) {
  .hamburger {
    display: none;
  }

  .navbar-links {
    display: flex;
  }

  .nav-link {
    display: inline-block;
  }

  .navbar-logo img {
    width: 120px;
  }
}
