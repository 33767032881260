/* Contact Us Container */
.contact-us {
  background: linear-gradient(to bottom, #e8d8e3, #584848);
  padding: 60px 20px;
  padding-top: 20px; /* Adjust for fixed navbar height */
  text-align: center;
  color: #141002;
  font-family: 'Arial', sans-serif;
}

.contact-heading {
  font-size: 32px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  color: #141002;
}

.contact-description {
  font-size: 16px;
  margin: 0 0 30px;
  line-height: 1.6;
  color: #110116;
}

/* Contact Cards */
.contact-card {
  background: #222;
  padding: 25px;
  margin: 20px auto;
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
}

.contact-card-title {
  color: #ffc107;
  font-size: 22px;
  margin-bottom: 15px;
  font-weight: bold;
}

.contact-card-description {
  font-size: 15px;
  color: #ddd;
  margin-bottom: 20px;
  line-height: 1.6;
}

/* Contact Details */
.contact-detail {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #ccc;
  margin-bottom: 15px;
}

.contact-detail i {
  color: #ffc107;
  margin-right: 10px;
  font-size: 20px;
}

/* Links */
.email-link,
.whatsapp-link {
  margin-left: auto;
  font-size: 14px;
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease, color 0.3s ease;
  font-weight: bold;
}

/* Email Button */
.email-link {
  background-color: #ffc107; /* Yellow background */
  color: #222; /* Dark text for contrast */
}

.email-link:hover {
  background-color: #e6a700; /* Slightly darker yellow for hover */
  transform: scale(1.05);
  color: #111; /* Ensures text remains visible on hover */
}

/* WhatsApp Button */
.whatsapp-link {
  background: linear-gradient(to right, #2c3e50, #34495e); /* Dark blue gradient */
  color: #f0f0f0; /* Light text for contrast */
}

.whatsapp-link:hover {
  background: linear-gradient(to right, #34495e, #2c3e50); /* Reverse gradient on hover */
  color: #fff;
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-card {
    width: 95%;
    margin: 10px auto;
  }

  .contact-card-title {
    font-size: 20px;
  }

  .contact-detail {
    flex-direction: column;
    align-items: flex-start;
  }

  .email-link,
  .whatsapp-link {
    margin-left: 0;
    margin-top: 8px;
  }
}
