/* Main Container */
.About{
  background: linear-gradient(to bottom, #e8d8e3, #584848);
  padding: 60px 20px;
  padding-top: 20px; /* Adjust for fixed navbar height */
  text-align: center;
  color: #141002;
  font-family: 'Arial', sans-serif;
}
.about-container {
  background: linear-gradient(to bottom, #3a3a3a, #1a1a1a);
  color: white;
  padding: 30px;
  padding-top: 20px; /* Adjust for fixed navbar height */
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6); /* Enhanced shadow for depth */
  max-width: 1200px;
  margin: auto; /* Center the container */
  font-family: 'Arial', sans-serif;
}

/* Main Heading */
.main-heading {
  text-align: center;
  font-size: 36px;
  color: #ffc107;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  letter-spacing: 1px;
}

/* Intro Section */
.intro-section {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.8;
  color: silver;
  text-align: justify;
}

.hilight {
  color: #ffc107;
  font-weight: bold;
}

/* Sections */
.sections {
  margin-top: 20px;
}

/* Section Title */
.section-title {
  font-size: 24px;
  margin: 15px 0;
  cursor: pointer;
  color: #ffffff;
  transition: all 0.3s ease;
  border-left: 5px solid #ffc107;
  padding-left: 15px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
}

.section-title:hover {
  color: #ffc107;
  transform: translateX(10px);
  text-shadow: 1px 1px 5px rgba(255, 193, 7, 0.7);
}

/* Section Content */
.section-content {
  margin-left: 20px;
  font-size: 18px;
  line-height: 1.8;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  animation: fadeIn 0.5s ease-in-out;
}

.section-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.section-content ul li {
  margin: 8px 0;
  padding: 8px 0;
  font-size: 16px;
  color: silver;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.section-content ul li:hover {
  background: rgba(255, 193, 7, 0.1);
  color: #ffc107;
  transform: translateY(-3px);
}

/* Animation for Fade-In */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
