* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* General Container */
.home-container {
  padding: 20px;
  padding-top: 100px; /* Adjust for fixed navbar height */
  background: linear-gradient(to bottom, #222, #444);
  color: white;
  font-family: 'Arial', sans-serif;
  min-height: 100vh;
  background-image: url('../../../public/Models/4.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Background Effect */
.background-effect {
  height: 300px;
  background: linear-gradient(to bottom right, #1a1a1a, #3a3a3a, #444);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  margin: 20px auto; /* Centers horizontally when width is set */
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
  width: 80%;
  padding: 20px;
  opacity: 0.95;
  text-align: center;
}

.background-effect:hover {
  background: linear-gradient(to bottom right, #444, #3a3a3a, #1a1a1a);
  transition: background 0.5s ease-in-out;
}

/* Title and Subtitle Text */
.center-title {
  text-align: center;
  animation: fadeIn 1.5s ease-in-out;
  margin-bottom: 20px;
}

.logo-image1 {
  width: 180px;
  height: auto;
  margin-bottom: 20px;
}

.title-text {
  font-size: 2rem;
  color: #ffc107;
  font-weight: bold;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
  margin-bottom: 10px;
}

.subtitle-text {
  font-size: 1.2rem;
  color: #ddd;
  font-weight: 500;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  margin-top: 10px;
}

/* Section Containers */
.services-section {
  margin: 20px auto;
  padding: 20px;
  background: #333;
  border: 1px solid #555;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  width: 80%;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0.9;
}

.alrumeli-section1 {
  margin: 20px auto;
  padding: 20px;
  background: #333;
  border: 1px solid #555;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  width: 80%;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0.9;
}

.services-section:hover,
.alrumeli-section1:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.7);
}

/* Section Headings */
.services-section h3,
.alrumeli-section1 h3 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #ffc107;
  font-weight: bold;
  text-transform: uppercase;
}

/* Services List */
.services-list {
  list-style: none;
  padding: 0;
  font-size: 1rem;
  color: #ddd;
  text-align: left;
  margin: 0 auto;
  max-width: 600px;
}

.services-list li {
  margin: 10px 0;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.services-list li:before {
  content: '•';
  font-size: 1.5rem;
  color: #ffc107;
  margin-right: 10px;
}

.services-list li:hover {
  color: #ffc107;
  transform: translateX(10px);
  transition: all 0.3s ease;
}

/* Video Box */
.video-box {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 15px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
}

.video-box iframe {
  width: 100%;
  max-width: 720px;
  height: 405px;
  border: none;
  border-radius: 10px;
}

/* Responsive Design */
@media (max-width: 320px) {
  .background-effect {
    width: 90%;
    padding: 15px;
  }
  .title-text {
    font-size: 1rem;
  }
  .subtitle-text {
    font-size: 0.9rem;
  }
  .services-section,
  .alrumeli-section1 {
    width: 90%;
    padding: 15px;
  }
  .video-box {
    max-width: 280px;
  }
}

@media (max-width: 768px) {
  .background-effect {
    width: 90%;
  }
  .title-text {
    font-size: 1.5rem;
  }
  .services-section,
  .alrumeli-section1 {
    width: 90%;
  }
}

@media (min-width: 1024px) {
  .background-effect {
    width: 80%;
  }
  .title-text {
    font-size: 2.5rem;
  }
  .services-section,
  .alrumeli-section1 {
    width: 75%;
  }
}

@media (min-width: 1440px) {
  .background-effect {
    width: 70%;
  }
  .title-text {
    font-size: 3rem;
  }
  .services-section,
  .alrumeli-section1 {
    width: 70%;
  }
}

@media (min-width: 2560px) {
  .background-effect {
    width: 65%;
  }
  .title-text {
    font-size: 3.5rem;
  }
  .services-section,
  .alrumeli-section1 {
    width: 60%;
  }
}

@media (min-width: 3840px) {
  .background-effect {
    width: 60%;
  }
  .title-text {
    font-size: 4rem;
  }
  .services-section,
  .alrumeli-section1 {
    width: 55%;
  }
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
