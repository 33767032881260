/* General Container */
.block-delivery-container {
  padding: 20px;
  padding-top: 210px; /* Adjust for fixed navbar height */
  max-width: 1400px; /* Maximum width for content */
  margin: auto;
  text-align: center;
}

.content-container {
  display: flex;
  justify-content: space-between;
  gap: 30px; /* Spacing between sections */
  align-items: flex-start; /* Ensures alignment at the top */
}

/* Block Details Section and Floating Quote Form */
.block-details-section {
  flex: 3; /* Larger section for block details */
}

.quote-form-container {
  flex: 1; /* Smaller section for the quote form */
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
  position: sticky; /* Make the form float */
  top: 20px; /* Distance from the top when scrolling */
  height: fit-content;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10; /* Ensure it stays above other content */
}

/* Block Grid Layout */
.block-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 40px; /* Increased spacing between blocks */
  justify-content: center;
}

.block-item {
  flex: 0 1 calc(50% - 40px); /* Ensures two blocks per row */
  background: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
}

.block-item:hover {
  transform: scale(1.05); /* Slight enlargement on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Video Size Adjustments */
.block-item .video-container {
  position: relative;
  width: 100%; /* Full width of the block */
  padding-top: 56.25%; /* Aspect ratio for 16:9 videos */
  overflow: hidden;
  border-radius: 10px;
  background-color: #000; /* Fallback background color */
}

.block-item .block-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Fills the container width */
  height: 100%; /* Fills the container height */
  object-fit: cover; /* Maintains aspect ratio while filling the frame */
}

/* Button and Overlay Adjustments */
.block-item .show-details-button {
  margin-top: 20px;
  padding: 15px 25px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px; /* Larger font size */
}

.details-overlay {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.9); /* Semi-transparent white overlay */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 18px; /* Larger text size for readability */
  line-height: 1.6; /* Improved readability */
  overflow-y: auto;
}

/* Map Popup */
.map-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.map-container {
  background: white;
  padding: 20px;
  border-radius: 15px;
  width: 95%;
  max-width: 1000px;
}

.map-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.map-buttons button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

/* Quote Actions Section */
.quote-actions {
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between buttons */
  margin-top: 20px;
}

.whatsapp-button,
.email-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.whatsapp-button {
  background-color: #25d366; /* WhatsApp green */
}

.whatsapp-button:hover {
  background-color: #20b858; /* Darker green on hover */
  transform: scale(1.05); /* Slight zoom effect */
}

.email-button {
  background-color: #007bff; /* Email blue */
}

.email-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: scale(1.05); /* Slight zoom effect */
}

.email-button i,
.whatsapp-button i {
  margin-right: 8px; /* Space between icon and text */
  font-size: 20px;
}
