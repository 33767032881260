/* Main app container */
.App {
  text-align: center;
  font-family: Arial, sans-serif; /* Added font-family for better readability */
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Link styling */
.App-link {
  color: #61dafb;
  text-decoration: none;
}

.App-link:hover {
  color: #21a1f1;
  text-decoration: underline;
}
/* General Styles for Default View */
body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f4;
}

